import React from "react"
import "../styles/stories.css"

export default function Stories(props) {

    function renderStories(stories) {
        return stories.map((item, i) => {
            if (i % 2 === 0) {
                return (
                    <div key={i} className="story">
                        <div className="storyTextContainer">
                            <div className={!item.title ? "" : "storyTitle"}>{item.title}</div>
                            <div className="storyText">{item.text}</div>
                        </div>
                        <div className="storyImageContainer">
                            <img className="storyImage" src={item.image} />
                        </div>
                    </div>
                )
            }
            else if (i % 2 === 1) {
                return (
                    <div key={i} className="story story__odd">
                        <div className="storyImageContainer">
                            <img className="storyImage" src={item.image} />
                        </div>
                        <div className="storyTextContainer">
                            <div className={!item.title ? "" : "storyTitle"}>{item.title}</div>
                            <div className="storyText">{item.text}</div>
                        </div>
                    </div>
                )
            }
        });
    }

    return (
        <div className="stories">
            <div className="storiesContainer">
                {renderStories(props.stories)}
            </div>
        </div>
    )
}