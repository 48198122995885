import React from "react"
import "../styles/team.css"

export default function Team(props) {
    console.log(props.team)

    function renderTeam(list) {
        return list.map((item, i) => {
            return (
                <div key={i} className="teamItem">
                    <div className={item.image.length > 0 ? "teamImage" : "teamImage teamImagebg"}>
                        <img src={item.image} alt="" />
                    </div>
                    <div className="teamName">{item.name}</div>
                    <div className="teamStatus">{item.status}</div>
                </div>
            )
        })
    }

    return (
        <div className="team">
            <div className="teamTitle">Tiimi</div>
            <div className="teamContainer">
                {renderTeam(props.team)}
            </div>
        </div>
    )
}