import * as React from "react"
import { graphql } from 'gatsby'
import "../styles/main.css"
import Header from "../components/header.js"
import Hero from "../components/hero.js"
import People from "../components/people.js"
import Stories from "../components/stories.js"
import Team from "../components/team.js"
import CtaBanner from "../components/ctaBanner.js"
import Footer from "../components/footer.js"

const AboutPage = (props) => {

  const intro = props.props.intro;
  const people = props.props.people;
  const stories = props.props.stories;
  const team = props.props.team;
  const bigBanner = props.props.bigBanner;

  return (
    <main>
      <Header page={1}></Header>
      <Hero title={intro.title} text={intro.text} image={intro.image} orientation="horizontal"></Hero>
      <People people={people}></People>
      <Stories stories={stories}></Stories>
      <Team team={team}></Team>
      <CtaBanner bigBanner={bigBanner}></CtaBanner>
      <Footer></Footer>
    </main>
  )
}

const AboutPageExport = ({ data }) => {
  const params = data.allMdx.nodes[0].frontmatter
  return (
    <AboutPage props={params}></AboutPage>
  )
}

export default AboutPageExport

export const pageQuery = graphql`
query AboutPageQuery {
    allMdx(filter: {frontmatter: {templateKey: {eq: "about"}}}) {
      nodes {
        frontmatter {
          intro {
            image
            text
            title
          }
          bigBanner {
            buttonText
            title
          }
          people {
            image
            name
            text
          }
          stories {
            image
            text
            title
          }
          team {
            image
            name
            status
          }
        }
      }
    }
  }
  

`