import React from "react"
import "../styles/people.css"

export default function People(props) {

    function renderList(list) {
        return list.map((item, i) => (
            <div className="peopleItem">
                <div className="peopleItemContainer">
                    <div className="peopleItemImageContainer">
                        <img className="peopleImage" src={item.image} alt="" />
                        <div className="peopleName">{item.name}</div>
                    </div>
                    <div className="peopleText">{item.text}</div>
                </div>
            </div>
        )
        )
    }

    return (
        <div className="people">
            {renderList(props.people)}
        </div>
    )
}